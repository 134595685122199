<template>
    <section class="w-full max-w-6xl flex flex-col items-stretch gap-8 md:gap-16">
        <!-- Filters -->
        <Filters
            :show-view-toggle="false"
            :hidden-fields="this.hiddenFields"
            :filters="this.updatedFilters"
            :page="this.page"
            :total-pages="this.totalPages"
            :groups="this.groups"
            :type="this.type"
            :is-preview="this.isPreview"
            ref="filters"
            @is-loading="this.updateLoading"
            @update-results="this.updateResults"
            @update-pagination="this.updatePagination"
            @update-view="this.updateView"
            @paginate="this.paginate"
        />

        <!-- Fake loading blocks -->
        <div
            v-if="this.isLoading == true"
            :class="[
                this.newView == 'grid'
                ? 'grid gap-6 md:grid-cols-2 lg:grid-cols-4'
                : 'flex flex-row flex-nowrap gap-6',
                'py-6'
            ]"
        >
            <LoadingCard v-for="i in 4" />
        </div>

        <!-- Grid View -->
        <TaxonomyGrid
            v-else-if="this.isLoading == 'complete' && this.newView == 'grid' && this.results.length > 0"
            :results="this.results"
            :currentuser="this.currentuser"
            :type="this.type"
            :is-preview="this.isPreview"
        />

        <div v-else-if="this.isLoading == 'complete' && this.newView == 'grid' && this.results.length == 0">
            <div class="wrapper py-10">
                <div class="inner grid gap-3 p-8 shadow-popover rounded-lg">
                    <h2 class="heading-05">{{ this.noresultscopy.headline }}</h2>
                    <div class="richtext richtext--lg" v-html="this.noresultscopy.copy"></div>
                </div>
            </div>
        </div>

        <!-- Pagination (grid only) -->
        <Pagination
            v-if="this.isLoading == 'complete' && this.newView == 'grid' && this.totalPages > 1"
            :current-page="this.page"
            :total="this.total"
            :count="this.count"
            :per-page="this.perPage"
            :total-pages="this.totalPages"
            :previous-link="this.previousLink"
            :next-link="this.nextLink"
            :key="paginationKey"
            @paginate="this.paginate"
        />
    </section>
</template>

<style>
    [v-cloak] {
        display: none;
    }
</style>

<script>
    import axios from 'axios';
    import { ref, nextTick } from 'vue/dist/vue.esm-bundler';
    import Icon from './pieces/Icon.vue';
    import TaxonomyGrid from './taxonomy-results/TaxonomyGrid.vue';
    import Filters from './pieces/Filters.vue';
    import LoadingCard from './cards/LoadingCard.vue';
    import Pagination from './pieces/Pagination.vue';

    export default {
        props: {
            view: {
                type: String,
                require: true,
                default: 'grid'
            },
            filters: {
                type: Array,
                require: false,
                default: []
            },
            currentuser: {
                type: Object,
                require: false,
                default: () => {}
            },
            type: {
                type: String,
                require: false,
                default: 'module'
            },
            groups: {
                type: Array,
                require: false,
                default: []
            },
            showViewToggle: {
                type: Boolean,
                require: false,
                default: true
            },
            hiddenFields: {
                type: [Array, Object],
                require: false,
                default: []
            },
            isPreview: {
                type: Boolean,
                require: false,
                default: false
            },
            noresultscopy: {
                type: Object,
                require: false,
                default: () => {
                    return {
                        headline: 'No results found.',
                        copy: '<p>We\'re sorry! We are a growing site, and we\'ll be posting new resources regularly. Please check back often! Or you could <a class="underline text-blue-default" href="/newsletter">sign up for email updates</a> about Reading Universe.</p>'
                    };
                }
            }
        },
        data() {
            return {
                results: [],
                isLoading: true,
                newView: this.view,
                total: 0,
                count: 0,
                perPage: 10,
                totalPages: 0,
                previousLink: null,
                nextLink: null,
                paginationKey: 0,
                page: 1,
                updatedFilters: this.filters
            }
        },
        components: {
            Icon,
            TaxonomyGrid,
            Filters,
            LoadingCard,
            Pagination
        },
        methods: {
            paginate(targetPage) {
                this.page = targetPage;
                // Call the FilterSection's 'goToPage' method, which updates the hidden "page" input and submits the form
                this.$refs['filters'].updatePage(targetPage);
            },
            updatePagination(pagination) {
                this.pagination = pagination;
                this.selectedPage = pagination['current_page'];
                this.total = pagination['total'];
                this.count = pagination['count'];
                this.perPage = pagination['per_page'];
                this.totalPages = pagination['total_pages'];
                this.previousLink = pagination['links'] ? pagination['links']['previous'] : null;
                this.nextLink = pagination['links'] ? pagination['links']['next'] : null;
                this.paginationKey += 1;
            },
            updateView(view) {
                if (this.newView != view) {
                    this.newView = view;
                }
            },
            updateResults(results) {
                this.results = results;
            },
            updateLoading(arg) {
                this.isLoading = arg;
            },
            getSkillsFilterValues(groups, type) {
                return new Promise((resolve, reject) => {
                    let endpoint = '/api/skills-filter.json';
                    axios.get(endpoint, { params: { 'groups': groups, 'type': type } })
                        .then(response => {

                            // check for skills in URL params
                            const queryString = window.location.search;
                            const urlParams = new URLSearchParams(queryString);
                            const activeSkill = urlParams.get('skills') || '';
                            console.log('activeSkill:', activeSkill);

                            let options = response.data.data;

                            // Add the default option
                            options.unshift({
                                'slug': '',
                                'label': 'Filter by Skill',
                                'selected': activeSkill === ''
                            });

                            // If activeSkill has a value, find and mark the corresponding option as selected
                            if (activeSkill !== '') {
                                const selectedOption = options.find(option => option.slug === activeSkill);
                                if (selectedOption) {
                                    selectedOption.selected = true;
                                }
                            }

                            let skillsFilterIndex = this.updatedFilters.findIndex(filter => filter.handle === 'skills');
                            if (skillsFilterIndex > -1) {
                                this.updatedFilters[skillsFilterIndex]['items'] = options;
                            }
                            console.log('options:', options);
                            resolve();
                        })
                        .catch(error => {
                            console.error('Failed to get skills filter values:', error);
                            reject(error);
                        });
                });
            }
        },
        async mounted() {
            // Get the page number from the URL, if present
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            // Get page number, if present
            const pageNumber = urlParams.get('page');
            // Initialize
            this.page = pageNumber ? parseInt(pageNumber) : 1;
            // Get the results on page load

            // Initialize Skills filter, if present
            if (this.filters && this.filters.some(filter => filter.handle == 'skills')) {
                try {
                    await this.getSkillsFilterValues(this.groups, this.type);
                } catch (error) {
                    console.error('Failed to initialize skills filter:', error);
                }
            }

            this.$refs['filters'].getInitialFormState();
            this.$refs['filters'].getResults();
        }
    }
</script>
